<template>
    <div class="app-household-details">
        <app-loader v-if="loading.customer || loading.summary"></app-loader>

        <div class="base-info">
            <router-link v-if="uuid" class="name" :to="{ name: 'households-local-details', params: { uuid } }">
                <span>{{ fullname }}</span>
            </router-link>

            <div v-else class="name">
                <span>{{ fullname }}</span>
            </div>

            <div class="status">Account status: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
        </div>

        <app-table-details
            :rows="rows.summary"
            :data="formatted_summary"

            title="Summary"
        />

        <router-link v-if="uuid" class="btn btn-secondary btn-full-width" :to="{ name: 'households-local-details', params: { uuid } }">View more details</router-link>
        <button v-else class="btn btn-secondary btn-full-width" disabled>View more details</button>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
    },

    data() {
        return {
            loading: {
                customer: false,
                summary: false,
            },

            customer: {},
            summary: {},

            rows: {
                summary: [
                    { key: 'UUID',               title: 'HouseHoldUUID'                 },
                    { key: 'HouseholdID',        title: 'Kaltura Household ID'          },
                    { key: 'MasterUserID',       title: 'Kaltura Master User ID'        },
                    { key: 'DefaultUserID',      title: 'Kaltura Default User ID'       },
                    { key: 'OTTUsername',        title: 'Kaltura OTT Username'          },
                    { key: 'ParentalPIN',        title: 'Kaltura Household PIN'         },
                    { key: 'ParentalPINEnabled', title: 'Kaltura Household PIN Enabled' },
                ],
            },
        }
    },

    computed: {
        customer_uuid() {
            return this.uuid
        },

        fullname() {
            const fullname = [this.customer.FirstName, this.customer.LastName].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        status() {
            return this.loading.customer ? {
                text: 'Loading',
                value: 'info',
            } : (
                this.customer.Active ? {
                    text: 'Active',
                    value: 'success',
                } : {
                    text: 'Inactive',
                    value: 'danger',
                }
            )
        },

        formatted_summary() {
            return {
                UUID: this.summary.UUID ? this.summary.UUID : '',
                HouseholdID: this.summary.HouseholdID ? this.summary.HouseholdID : '',
                MasterUserID: this.summary.MasterUserID ? this.summary.MasterUserID : '',
                DefaultUserID: this.summary.DefaultUserID ? this.summary.DefaultUserID : '',
                OTTUsername: this.summary.OTTUsername ? this.summary.OTTUsername : '',
                ParentalPIN: this.summary.ParentalPIN ? this.summary.ParentalPIN : '',
                ParentalPINEnabled: typeof this.summary.ParentalPINEnabled == 'boolean' ? this.summary.ParentalPINEnabled ? 'true' : 'false' : '',
            }
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getCustomerDetails()

            this.getCustomerHouseholds()
        },

        getCustomerDetails() {
            if (this.customer_uuid) {
                this.loading.customer = true

                this.$store.dispatch('getCustomerByUUID', { uuid: this.customer_uuid })
                    .then(customer => {
                        this.customer = customer

                        this.loading.customer = false
                    })
                    .catch(error => {
                        console.log(error)

                        this.loading.customer = false

                        this.closeSidebar()
                    })
            }
        },

        getCustomerHouseholds() {
            if (this.customer_uuid) {

                this.loading.summary = true

                this.summary = {}

                this.$store.dispatch('getCustomerHouseholds', { uuid: this.customer_uuid })
                    .then(response => {
                        if (Array.isArray(response) && response.length) {
                            this.summary = response[0]

                            this.loading.summary = false
                        } else {
                            this.loading.summary = false
                        }
                    })
                    .catch(error => {
                        console.log(error)

                        this.loading.summary = false

                        this.closeSidebar()
                    })
            }
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    watch: {
        customer_uuid() {
            this.init()
        },
    },
}
</script>

<style lang="scss">
.app-household-details {
    position: relative;
    min-height: 100%;
    padding: 0 32px 40px;

        .base-info {
            display: flex;
            justify-content: space-between;
            padding: 36px 0 64px;

            .name {
                display: flex;
                flex: 1;
                position: relative;
                min-width: 0;
                max-width: fit-content;
                margin-right: 32px;
                padding-right: 8px + 18px;
                font-size: 32px;
                font-weight: bold;
                line-height: 40px;
                color: var(--color-sidebar-link);
                transition: color $transition-duration-primary;
                cursor: pointer;

                span {
                    @include text-overflow();
                }

                &::before {
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    display: block;
                    width: 24px;
                    height: 24px;

                    color: var(--color-icon-action);

                    @include icon($icon-account-details);
                }


                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    display: block;
                    width: 0;
                    height: 4px;
                    background-color: var(--color-tab-hover);
                    border-radius: $border-radius-primary;
                    transition: width $transition-duration-primary;
                    content: '';
                }

                &:hover {
                    color: var(--color-tab-hover);

                    &::after {
                        width: 80px;
                    }
                }
            }

            .status {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                line-height: 24px;
                color: var(--color-sidebar-text);

                .label {
                    margin-left: 16px;
                    padding: 4px 12px;
                    font-size: 16px;
                    font-weight: bold;
                    text-align: center;
                    border-radius: $border-radius-primary;

                    @each $type in $table-tag-types {
                        &.#{ $type } {
                            color: map-get($table-tag-colors, $type);
                            background-color: map-get($table-tag-backgrounds, $type);
                        }
                    }
                }
            }
        }

        .app-table-details {
            margin-bottom: 30px;

            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }
}

@media (max-width: $tablet-size) {
    .app-household-details {
        padding: 0 40px 40px;

        .base-info {
            padding: 32px 0 64px;

            .name {
                margin-right: 21px;
            }

            .status {
                font-size: 14px;

                .label {
                    margin-left: 8px;
                    padding: 2px 8px;

                    font-size: 12px;

                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>